import {baseModalUrl, ErrorNames, ModalState, ModalTheme, productPageFedopsEvent} from '../constants';
import {IStoreInfo} from '@wix/wixstores-graphql-schema';
import {BiButtonActionType} from '@wix/wixstores-client-core/dist/es/src/constants';
import {formatCustomTextFields} from '@wix/wixstores-client-core/dist/es/src/productOptions/productUtils';
import {VolatileCartService} from './VolatileCartService';
import {CheckoutService} from './CheckoutService';
import {ModalManager} from '@wix/wixstores-client-core/dist/es/src/modalManager/modalManager';
import {StoreInfoService} from './StoreInfoService';
import {IProductDTO, IPropsInjectedByViewerScript, UserInput, UserInputErrors} from '../types/app-types';
import {SiteStore} from '@wix/wixstores-client-core/dist/es/src/viewer-script/site-store/siteStore';
import {LostBusinessNotifier} from './LostBusinessNotifier';
import {SPECS} from '../specs';

export class BuyNowService {
  private readonly checkoutService: CheckoutService;
  private readonly volatileCartService: VolatileCartService;
  private readonly lostBusinessNotifier: LostBusinessNotifier;
  private readonly storeInfoService: StoreInfoService;
  private readonly modalManger: ModalManager;

  constructor(
    private readonly siteStore: SiteStore,
    private readonly reportError: (e) => any,
    private readonly isInvalid: (userInputs: UserInput) => {isInvalid: boolean; validations: UserInputErrors},
    private readonly reportButtonAction: (buttonType: BiButtonActionType) => void,
    private readonly nextProps: (additionalProps: Partial<IPropsInjectedByViewerScript>) => void,
    private readonly fedopsLogger: any
  ) {
    const openModal = async (url, width, height, bareTheme) => {
      const theme = bareTheme ? ModalTheme.BARE : ModalTheme.DEFAULT;
      return this.siteStore.windowApis.openModal(url, {width, height, theme});
    };

    this.volatileCartService = new VolatileCartService(this.siteStore.httpClient);
    this.lostBusinessNotifier = new LostBusinessNotifier(this.siteStore.httpClient);
    this.checkoutService = new CheckoutService(this.siteStore, this.siteStore.httpClient);
    this.modalManger = new ModalManager({openModal}, baseModalUrl, true, this.siteStore.instance);
    this.storeInfoService = new StoreInfoService(this.siteStore);
  }

  private readonly getPathFromUrl = (url: string) => {
    return url.split(/[?#]/)[0];
  };

  public handleBuyNow = async (
    accessibilityEnabled: boolean,
    product: IProductDTO,
    userInputs: UserInput
  ): Promise<void> => {
    const storeInfo = await this.storeInfoService.fetchStoreInfo().catch(() => {
      this.nextProps({errors: {errorName: ErrorNames.StoreInfoError}});
      return {} as IStoreInfo;
    });

    if (storeInfo === {}) {
      return;
    }

    const validationObject = this.isInvalid(userInputs);

    if (validationObject.isInvalid) {
      this.nextProps({
        isProductSubmitted: true,
        userInputErrors: validationObject.validations,
      });
      return;
    }

    this.reportButtonAction(BiButtonActionType.BuyNow);

    const canStoreShip = product.productType === 'digital' || storeInfo.canStoreShip;

    if (this.siteStore.isPreviewMode() || this.siteStore.isEditorMode()) {
      const biParams = {origin: 'product page', mode: 'editor', isMerchant: true};

      if (!canStoreShip) {
        // tslint:disable no-floating-promises
        this.siteStore.biLogger.showShippingPopupSf({
          type: 'merchant pop-up',
          ...biParams,
        });
        return this.modalManger.openSetShippingMethod();
      } else if (!storeInfo.hasCreatedPaymentMethods) {
        // tslint:disable no-floating-promises
        this.siteStore.biLogger.showMerchantPaymentPopupSf(biParams);
        return this.modalManger.openSetPaymentMethod();
      } else if (!storeInfo.isPremium) {
        // tslint:disable no-floating-promises
        this.siteStore.biLogger.showMerchantUpgradePopupSf(biParams);
        const response = await this.modalManger.openUpgradeToPremium();
        if (!(response && response.proceed)) {
          return;
        }
      } else {
        // tslint:disable no-floating-promises
        this.siteStore.biLogger.viewCheckoutInLiveSitePopupSf(biParams);
        return this.modalManger.openNotInLiveSite();
      }
    } else if (!storeInfo.isPremium || !storeInfo.hasCreatedPaymentMethods || !canStoreShip) {
      this.siteStore.experiments.enabled(SPECS.ENABLE_LOST_BUSINESS_EMAIL) &&
        storeInfo.isPremium &&
        this.lostBusinessNotifier.notify();
      this.siteStore.biLogger.notAcceptPaymentsVisitorPopupSf({origin: 'product page'});
      this.nextProps({modalState: ModalState.OPEN});
      return this.modalManger.openNoOnlinePayments().then(() => {
        this.nextProps({modalState: ModalState.CLOSE});
      });
    }

    this.fedopsLogger.interactionStarted(productPageFedopsEvent.BuyNow);
    const cartId = await this.volatileCartService.getStandaloneCartId(
      product.id,
      userInputs.selection.map(selected => selected.id),
      userInputs.quantity[0],
      formatCustomTextFields(product, userInputs).map(customTextField => {
        return {title: customTextField.customText.title, value: customTextField.answer};
      })
    );

    let thankYouPageSectionUrl, cartPageSectionUrl, checkoutUrl;
    [thankYouPageSectionUrl, cartPageSectionUrl, checkoutUrl] = await Promise.all([
      this.siteStore.getSectionUrl(this.siteStore.pageMap.thankyou),
      this.siteStore.getSectionUrl(this.siteStore.pageMap.cart),
      this.siteStore.getSectionUrl(this.siteStore.pageMap.checkout),
    ]).catch(this.reportError);

    const checkoutRelativeUrl = checkoutUrl.relativeUrl;
    const thankYouPageUrl = this.getPathFromUrl(thankYouPageSectionUrl.url);
    const cartPageUrl = this.getPathFromUrl(cartPageSectionUrl.url);
    const deviceType = this.siteStore.isDesktop() ? 'desktop' : 'mobile';

    this.fedopsLogger.interactionEnded(productPageFedopsEvent.BuyNow);

    await this.checkoutService.navigateToCheckout({
      cartId,
      isFastFlow: false,
      isPickupFlow: false,
      checkoutRelativeUrl,
      siteBaseUrl: this.siteStore.location.baseUrl,
      thankYouPageUrl,
      cartUrl: cartPageUrl,
      paymentMethodName: undefined,
      locale: this.siteStore.locale,
      deviceType,
      a11y: accessibilityEnabled,
    });
  };
}
